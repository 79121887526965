import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  teal,
  yellow,
} from "@material-ui/core/colors";

// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  // DEFAULT: "DEFAULT",
  // DARK: "DARK",
  // LIGHT: "LIGHT",
  // BLUE: "BLUE",
  // GREEN: "GREEN",
  // INDIGO: "INDIGO",
  GLS_LIGHT: "GLS_LIGHT",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Graph Colors
export const graph_colors = [
  deepOrange[500],
  blue[500],
  brown[500],
  cyan[500],
  deepPurple[500],
  amber[500],
  blueGrey[500],
  green[500],
  grey[500],
  indigo[500],
  lightBlue[500],
  lightGreen[500],
  lime[500],
  orange[500],
  pink[500],
  purple[500],
  teal[500],
  yellow[500],
];
