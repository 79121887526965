export const GLS_LIGHT = {
  primary: {
    blue: {
      50: "#757FCA",
      100: "#606CC6",
      200: "#4957C1",
      300: "#3343BC",
      400: "#1C2EB6",
      500: "#061AB1",
      600: "#061a9d",
      700: "#061a89",
      800: "#061a75",
      900: "#061a61",
    },
    mainBlue: "#061ab1",
    yellow: "#ffd100",
    white: "#ffffff",
    black: "#000000",
  },
  soft: {
    skyBlue: "#c8e7f2",
    pink: "#f3c8d4",
    green: "#c0e2ad",
  },
  accent: {
    electricBlue: "#1601FF",
    skyBlue: "#00C2F0",
    brightOrange: "#FF860D",
  },
  typography: {
    white: "#FFFFFF",
    black: "#000000",
    gray: "#8B8C8C",
    blue: "#061AB1",
  },
  support: {
    gray: "#B7B7B7",
  },
};
