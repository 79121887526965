// TO DO: Añadir datos de los dominios que falten
function getApiBasedInDomain() {
  const { domain } = document;
  let baseApiUrl;
  if (domain.includes("gls-spain.es")) {
    baseApiUrl = process.env.REACT_APP_BASE_URL_PRODUCTION_AD;
  } else if (domain.includes("gls-spain.dev")) {
    baseApiUrl = process.env.REACT_APP_BASE_URL_DEV_PRODUCTION_AD;
  } else {
    baseApiUrl = process.env.REACT_APP_BASE_URL_LOCAL_AD;
  }
  return baseApiUrl;
}

export function getEnvironment() {
  const { domain } = document;
  let environment;
  if (domain.includes("gls-spain.es")) {
    environment = "PRO";
  } else if (domain.includes("dev-agency")) {
    environment = "DEV";
  } else {
    environment = "LOCAL";
  }
  return environment;
}

export default getApiBasedInDomain;
