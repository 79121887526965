import { green, grey } from "@material-ui/core/colors";
import { GLS_LIGHT } from "./colors";
import { THEMES } from "../constants";
import { darken } from "polished";

// const customBlue = {
//   50: "#e9f0fb",
//   100: "#c8daf4",
//   200: "#a3c1ed",
//   300: "#7ea8e5",
//   400: "#6395e0",
//   500: "#4782da",
//   600: "#407ad6",
//   700: "#376fd0",
//   800: "#2f65cb",
//   900: "#2052c2",
// };

// const defaultVariant = {
//   name: THEMES.DEFAULT,
//   palette: {
//     type: "light",
//     primary: {
//       main: customBlue[700],
//       contrastText: "#FFF",
//     },
//     secondary: {
//       main: customBlue[500],
//       contrastText: "#FFF",
//     },
//     background: {
//       default: "#F7F9FC",
//       paper: "#FFF",
//     },
//   },
//   header: {
//     color: grey[500],
//     background: "#FFF",
//     search: {
//       color: grey[800],
//     },
//     indicator: {
//       background: customBlue[600],
//     },
//   },
//   footer: {
//     color: grey[500],
//     background: "#FFF",
//   },
//   sidebar: {
//     color: grey[200],
//     background: "#233044",
//     header: {
//       color: grey[200],
//       background: "#233044",
//       brand: {
//         color: customBlue[500],
//       },
//     },
//     footer: {
//       color: grey[200],
//       background: "#1E2A38",
//       online: {
//         background: green[500],
//       },
//     },
//     badge: {
//       color: "#FFF",
//       background: customBlue[500],
//     },
//   },
//   label: {
//     color: "inherit",
//     background: "inherit",
//   },
//   title: {
//     color: "inherit",
//   },
//   button: {
//     primary: {
//       main: customBlue[700],
//       contrastText: "#FFF",
//       border: "inherit",
//       hover: {
//         main: "inherit",
//         contrastText: "inherit",
//         border: "inherit",
//       },
//       disabled: {
//         main: "inherit",
//         contrastText: "inherit",
//         border: "inherit",
//       },
//     },
//     secondary: {
//       main: customBlue[500],
//       contrastText: "#FFF",
//       border: "inherit",
//       hover: {
//         main: darken(0.15, customBlue[500]),
//         contrastText: "#FFF",
//         border: "inherit",
//       },
//       disabled: {
//         main: "inherit",
//         contrastText: "inherit",
//         border: "inherit",
//       },
//     },
//   },
// };

// const darkVariant = merge(defaultVariant, {
//   name: THEMES.DARK,
//   palette: {
//     type: "dark",
//     primary: {
//       main: customBlue[600],
//       contrastText: "#FFF",
//     },
//     background: {
//       default: "#1B2635",
//       paper: "#233044",
//     },
//     text: {
//       primary: "rgba(255, 255, 255, 0.95)",
//       secondary: "rgba(255, 255, 255, 0.5)",
//     },
//   },
//   header: {
//     color: grey[300],
//     background: "#1B2635",
//     search: {
//       color: grey[200],
//     },
//   },
//   footer: {
//     color: grey[300],
//     background: "#233044",
//   },
//   button: {
//     primary: {
//       main: customBlue[600],
//       contrastText: "#FFF",
//     },
//   },
// });

// const lightVariant = merge(defaultVariant, {
//   name: THEMES.LIGHT,
//   palette: {
//     type: "light",
//   },
//   header: {
//     color: grey[200],
//     background: customBlue[800],
//     search: {
//       color: grey[100],
//     },
//     indicator: {
//       background: red[700],
//     },
//   },
//   sidebar: {
//     color: grey[900],
//     background: "#FFF",
//     header: {
//       color: "#FFF",
//       background: customBlue[800],
//       brand: {
//         color: "#FFFFFF",
//       },
//     },
//     footer: {
//       color: grey[800],
//       background: "#F7F7F7",
//       online: {
//         background: green[500],
//       },
//     },
//   },
// });

// const blueVariant = merge(defaultVariant, {
//   name: THEMES.BLUE,
//   palette: {
//     type: "light",
//   },
//   sidebar: {
//     color: "#FFF",
//     background: customBlue[700],
//     header: {
//       color: "#FFF",
//       background: customBlue[800],
//       brand: {
//         color: "#FFFFFF",
//       },
//     },
//     footer: {
//       color: "#FFF",
//       background: customBlue[800],
//       online: {
//         background: "#FFF",
//       },
//     },
//     badge: {
//       color: "#000",
//       background: "#FFF",
//     },
//   },
// });

// const greenVariant = merge(defaultVariant, {
//   name: THEMES.GREEN,
//   palette: {
//     primary: {
//       main: green[800],
//       contrastText: "#FFF",
//     },
//     secondary: {
//       main: green[500],
//       contrastText: "#FFF",
//     },
//   },
//   header: {
//     indicator: {
//       background: green[600],
//     },
//   },
//   sidebar: {
//     color: "#FFF",
//     background: green[700],
//     header: {
//       color: "#FFF",
//       background: green[800],
//       brand: {
//         color: "#FFFFFF",
//       },
//     },
//     footer: {
//       color: "#FFF",
//       background: green[800],
//       online: {
//         background: "#FFF",
//       },
//     },
//     badge: {
//       color: "#000",
//       background: "#FFF",
//     },
//   },
//   button: {
//     secondary: {
//       main: green[500],
//       contrastText: "#FFF",
//       hover: {
//         main: darken(0.15, green[500]),
//         contrastText: "#FFF",
//       },
//     },
//   },
// });

// const indigoVariant = merge(defaultVariant, {
//   name: THEMES.INDIGO,
//   palette: {
//     primary: {
//       main: indigo[600],
//       contrastText: "#FFF",
//     },
//     secondary: {
//       main: indigo[400],
//       contrastText: "#FFF",
//     },
//   },
//   header: {
//     indicator: {
//       background: indigo[600],
//     },
//   },
//   sidebar: {
//     color: "#FFF",
//     background: indigo[700],
//     header: {
//       color: "#FFF",
//       background: indigo[800],
//       brand: {
//         color: "#FFFFFF",
//       },
//     },
//     footer: {
//       color: "#FFF",
//       background: indigo[800],
//       online: {
//         background: "#FFF",
//       },
//     },
//     badge: {
//       color: "#000",
//       background: "#FFF",
//     },
//   },
//   button: {
//     secondary: {
//       main: indigo[400],
//       contrastText: "#FFF",
//       hover: {
//         main: darken(0.15, indigo[400]),
//         contrastText: "#FFF",
//       },
//     },
//   },
// });

const glsLightVariant = {
  name: THEMES.GLS_LIGHT,
  palette: {
    type: "light",
    primary: {
      main: GLS_LIGHT.primary.yellow,
      contrastText: GLS_LIGHT.typography.black,
    },
    secondary: {
      main: GLS_LIGHT.primary.mainBlue,
      contrastText: GLS_LIGHT.typography.white,
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: GLS_LIGHT.primary.blue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: GLS_LIGHT.primary.blue[800],
    header: {
      color: grey[200],
      background: GLS_LIGHT.primary.blue[500],
      brand: {
        color: GLS_LIGHT.primary.blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: GLS_LIGHT.primary.blue[500],
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: GLS_LIGHT.primary.blue[50],
    },
  },
  label: {
    color: GLS_LIGHT.primary.black,
  },
  title: {
    color: GLS_LIGHT.primary.mainBlue,
  },
  button: {
    primary: {
      main: GLS_LIGHT.primary.yellow,
      contrastText: GLS_LIGHT.typography.black,
      border: `1px solid ${GLS_LIGHT.primary.yellow}`,
      hover: {
        main: "inherit",
        contrastText: "inherit",
        border: `1px solid ${darken(0.15, GLS_LIGHT.primary.yellow)}`,
      },
    },
    secondary: {
      main: GLS_LIGHT.primary.white,
      contrastText: GLS_LIGHT.primary.mainBlue,
      border: `1px solid ${GLS_LIGHT.primary.mainBlue}`,
      hover: {
        main: GLS_LIGHT.primary.mainBlue,
        contrastText: GLS_LIGHT.typography.white,
        border: `1px solid ${GLS_LIGHT.primary.mainBlue}`,
      },
      disabled: {
        main: "inherit",
        contrastText: "inherit",
        border: "none",
      },
    },
  },
};

const variants: Array<VariantType> = [
  // defaultVariant,
  // darkVariant,
  // lightVariant,
  // blueVariant,
  // greenVariant,
  // indigoVariant,
  glsLightVariant,
];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
  label: Color;
  title: Color;
  button: Button;
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};
type Color = {
  color: string;
};
type Button = {
  primary: MainContrastTextType & {
    border: string;
    hover: MainContrastTextType & {
      border: string;
    };
  };
  secondary: MainContrastTextType & {
    border: string;
    hover: MainContrastTextType & {
      border: string;
    };
    disabled: MainContrastTextType & {
      border: string;
    };
  };
};
