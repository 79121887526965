/* eslint-disable prettier/prettier */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./mocks";

import { Provider } from "react-redux";
import store from "./redux/store/index";
import { hotjar } from 'react-hotjar';
import { getEnvironment } from "./utils/detectionDomain";


if (getEnvironment() === "PRO") {
  hotjar.initialize(3237629, 6);
  if (hotjar.initialized()) {
    hotjar.identify('USER_ID', { userProperty: 'value' });
    hotjar.event('button-click');
  }
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "en",
    detection: {
      order: ['navigator'],
      excludeCacheFor: ['localStorage'],
    },
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json'
    },
    react: { useSuspense: false }
  });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
