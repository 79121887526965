import * as React from "react";
import styled from "styled-components/macro";
// import { Link } from "react-router-dom";

import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
} from "@material-ui/core";

interface ListItemProps extends MuiListItemProps {
  component?: string;
  href?: string;
  button: boolean | undefined;
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItem = styled(MuiListItem)<ListItemProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;
function openInNewTab(url: string) {
  window.open(url, "_blank", "noopener,noreferrer");
}

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem
                button={true}
                onClick={() =>
                  openInNewTab(
                    "https://www.gls-spain.es/es/proteccion-de-datos"
                  )
                }
              >
                <ListItemText primary="Política de privacidad" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() =>
                  openInNewTab(
                    "https://www.gls-spain.es/es/terminos-y-condiciones"
                  )
                }
              >
                <ListItemText primary="Términos y condiciones" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() =>
                  openInNewTab(
                    "https://www.gls-spain.es/es/informacion-corporativa"
                  )
                }
              >
                <ListItemText primary="Información corporativa" />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem
              button={true}
              component="a"
              href="https://www.gls-spain.es"
            >
              <ListItemText
                primary={`© ${new Date().getFullYear()} - GLS Spain`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
