import React, { useState, useEffect } from "react";
import axios from "axios";
import styled, { createGlobalStyle } from "styled-components/macro";
import { GlobalStyleProps } from "../types/styles";
import { RouteType } from "../types/routes";
import Sidebar from "../components/Sidebar";
import Header from "../components/AppBar";
import Footer from "../components/Footer";
import { LoginWithJwtRequest } from "@gls_spain/agency-dashboard-types";
// import Settings from "../components/Settings";
import detectionDomain from "../utils/detectionDomain";

import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";

const drawerWidth = 258;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type DashboardPropsType = {
  routes: Array<RouteType>;
  width: "md" | "xs" | "sm" | "lg" | "xl";
};

const Dashboard: React.FC<DashboardPropsType> = ({
  children,
  routes,
  width,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var tokenAtlasSession = urlParams.get('token');


  if (sessionStorage.getItem("tokenAtlas") === null || sessionStorage.getItem("tokenAtlas") === "" || urlParams.has('token')) {
    sessionStorage.setItem(
      "tokenAtlas",
      tokenAtlasSession ? tokenAtlasSession : ""
    );
    if (urlParams.has('token') && sessionStorage.getItem("tokenAtlas") !== null) {
      window.location.href = "/"
    } else {
      window.location.href = "/404"
    }
  }


  var tokenAtlas = sessionStorage.getItem("tokenAtlas");

  const dataLogin: LoginWithJwtRequest = {
    login: {
      token: tokenAtlas ? tokenAtlas : "",
    },
  };

  const baseURL = detectionDomain();

  const [username, setUsername] = useState(
    sessionStorage.getItem("username")
      ? sessionStorage.getItem("username")
      : "Undefined"
  );
  const [name, setName] = useState(
    sessionStorage.getItem("name")
      ? sessionStorage.getItem("name")
      : "Undefined"
  );

  useEffect(() => {
    axios
      .post(baseURL + "/atlas/v1/loginWithJwt", dataLogin)
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem(
            "x-access-token",
            response.headers["x-access-token"]
          );
          sessionStorage.setItem("name", response.data.user.name);
          sessionStorage.setItem("username", response.data.user.username);
          setName(response.data.user.name);
          setUsername(response.data.user.username);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            username={username}
            nameU={name}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            username={username}
            nameU={name}
          />
        </Hidden>
      </Drawer>
      <AppContent>
        <Hidden mdUp implementation="js">
          <Header onDrawerToggle={handleDrawerToggle} />
        </Hidden>
        <MainContent p={isWidthUp("lg", width) ? 12 : 5}>
          {children}
        </MainContent>
        <Footer />
      </AppContent>
      {/* <Settings /> */}
    </Root>
  );
};

export default withWidth()(Dashboard);
