import { darken } from "polished";
import { VariantType } from "./variants";

const getOverrides = (theme: VariantType) => {
  const overrides = {
    MuiCard: {
      root: {
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
      },
    },
    MuiCardHeader: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
    MuiPickersDay: {
      day: {
        fontWeight: "300",
      },
      daySelected: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        "&:hover": {
          backgroundColor: darken(0.15, theme.palette.secondary.main),
        },
      },
    },
    MuiPickersYear: {
      root: {
        height: "64px",
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
    MuiPickersClock: {
      container: {
        margin: `32px 0 4px`,
      },
      pin: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: theme.palette.secondary.main,
      },
      thumb: {
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
      },
      noPoint: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
      clockNumberSelected: {
        color: theme.palette.secondary.contrastText,
      },
    },
    MuiPickerDTHeader: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
    MuiPickersTimePicker: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
        backgroundColor: theme.palette.secondary.main,
        "& .MuiPickersToolbarText-toolbarTxt": {
          color: theme.palette.secondary.contrastText,
        },
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      },
    },
    MuiChip: {
      root: {
        borderRadius: "6px",
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: theme.label.color,
        },
      },
    },
    MuiTypography: {
      h1: {
        color: theme.title.color,
      },
    },
    MuiButton: {
      root: {
        borderRadius: "30px",
      },
      containedPrimary: {
        border: theme.button.primary.border,
        "&:hover": {
          border: theme.button.primary.hover.border,
        },
        "&.Mui-disabled": {
          border: theme.button.secondary.disabled.border,
        },
      },
      containedSecondary: {
        color: theme.button.secondary.contrastText,
        backgroundColor: theme.button.secondary.main,
        border: theme.button.secondary.border,
        "&:hover": {
          backgroundColor: theme.button.secondary.hover.main,
          color: theme.button.secondary.hover.contrastText,
        },
        "&.Mui-disabled": {
          border: theme.button.secondary.disabled.border,
        },
      },
    },
  };

  return overrides;
};

export default getOverrides;
